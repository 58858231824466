<script setup>
import gsap from 'gsap'
import SplitType from 'split-type'
import GlobalEmitter from '~/glxp/utils/emitter'
import { useMouse } from '~~/composables/useMouse'
import { useIsMobile } from '~~/composables/useIsMobile'
import { Vec2 } from '~~/glxp/ogl/math/Vec2'
import { lerp, clamp } from '~/glxp/utils/math'

const props = defineProps({
  big: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  minimal: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: null,
  },
  tag: {
    type: String,
    default: 'button',
  },
  animate: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },

  // give the appearance of being disabled but still allow click
  pseudodisabled: {
    type: Boolean,
    default: false,
  },
})

const attrs = useAttrs()
const slots = useSlots()

/**
 * Hover animation
 */

const hasIcon = computed(() => !!props.icon)
const hasText = computed(() => slots.default)
const attributes = computed(() => {
  const attributes = {}

  if (attrs.target === '_blank') {
    attributes.rel = 'noopener noreferrer'
  }

  return { ...attributes }
})

const el = ref(null)
let st
function initializeAnimation() {
  const text = el.value.querySelector('.button-pill__text')
  st = new SplitType(text, { types: 'chars, words' })
}

onMounted(() => {
  initializeAnimation()
})

let tl
const bounds = reactive({
  el: {
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  content: {
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  icon: {
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  text: {
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
})

function getBounds() {
  if (!el.value) return
  const content = el.value.querySelector('.button-pill__content')
  const icon = el.value.querySelector('.button-pill__icon')
  const text = el.value.querySelector('.button-pill__text')

  const {
    width: elWidth,
    height: elHeight,
    top: elTop,
    right: elRight,
    bottom: elBottom,
    left: elLeft,
  } = el.value.getBoundingClientRect()

  bounds.el.width = elWidth
  bounds.el.height = elHeight
  bounds.el.top = elTop
  bounds.el.right = elRight
  bounds.el.bottom = elBottom
  bounds.el.left = elLeft

  const {
    width: contentWidth,
    height: contentHeight,
    top: contentTop,
    right: contentRight,
    bottom: contentBottom,
    left: contentLeft,
  } = content.getBoundingClientRect()

  bounds.content.width = contentWidth
  bounds.content.height = contentHeight
  bounds.content.top = contentTop
  bounds.content.right = contentRight
  bounds.content.bottom = contentBottom
  bounds.content.left = contentLeft

  if (hasIcon.value) {
    const {
      width: iconWidth,
      height: iconHeight,
      top: iconTop,
      right: iconRight,
      bottom: iconBottom,
      left: iconLeft,
    } = icon.getBoundingClientRect()

    bounds.icon.width = iconWidth
    bounds.icon.height = iconHeight
    bounds.icon.top = iconTop
    bounds.icon.right = iconRight
    bounds.icon.bottom = iconBottom
    bounds.icon.left = iconLeft
  }

  if (hasText.value) {
    const {
      width: textWidth,
      height: textHeight,
      top: textTop,
      right: textRight,
      bottom: textBottom,
      left: textLeft,
    } = text.getBoundingClientRect()

    bounds.text.width = textWidth
    bounds.text.height = textHeight
    bounds.text.top = textTop
    bounds.text.right = textRight
    bounds.text.bottom = textBottom
    bounds.text.left = textLeft
  }
}

function animateMouseenter() {
  if (!el.value || props.pseudodisabled) return
  const icon = el.value.querySelector('.button-pill__icon')
  const text = el.value.querySelector('.button-pill__text')

  const elTop = bounds.el.top
  const elBottom = bounds.el.bottom
  const contentWidth = bounds.content.width
  const contentLeft = bounds.content.left

  tl?.kill()
  tl = gsap.timeline()

  if (hasIcon.value) {
    const iconTop = bounds.icon.top
    const iconWidth = bounds.icon.width
    const iconBottom = bounds.icon.bottom

    if (props.icon === 'download') {
      const chevron = icon.querySelector('.icon-download__chevron')
      const line = icon.querySelector('.icon-download__line')

      // animate icon down
      tl.addLabel('part1')
        .to(
          line,
          {
            scaleX: 0,
            transformOrigin: 'top right',
            duration: 0.2,
            ease: 'power2.inOut',
          },
          'start'
        )
        .to(
          chevron,
          {
            scaleX: 0.4,
            scaleY: 1.1,
            duration: 0.4,
            ease: 'power2.in',
          },
          'start'
        )
        .to(
          chevron,
          {
            y: `${elBottom - iconTop}px`,
            opacity: 0,

            duration: 0.4,
            ease: 'power3.in',
          },
          'start'
        )
        .to(icon, {
          x: `${contentWidth - iconWidth}px`,
          duration: 0,
        })
        .to(chevron, {
          y: `${elTop - iconBottom}px`,
          duration: 0,
        })
        .addLabel('part2')
        .to(
          line,
          {
            transformOrigin: 'top left',
            scaleX: 1,
            duration: 0.2,
            ease: 'power2.inOut',
          },
          'part2'
        )
        .to(
          chevron,
          {
            scaleX: 1,
            scaleY: 1,
            duration: 0.4,
            ease: 'power2.out',
          },
          'part2'
        )
        .to(
          chevron,
          {
            y: 0,
            opacity: 1,
            duration: 0.4,
            ease: 'power3.out',
          },
          'part2'
        )
    } else {
      // animate icon to the right
      tl.to(icon, {
        duration: 0.8,
        ease: 'power3.inOut',
        x: `${contentWidth - iconWidth}px`,
      })
    }
  }

  if (hasText.value) {
    const { chars } = st
    const textLeft = bounds.text.left
    tl.to(
      chars,
      {
        opacity: 0,
        duration: 0.15,
        stagger: {
          from: 'random',
          amount: 0.15,
        },
      },
      0
    )
      .to(
        text,
        {
          x: `${contentLeft - textLeft}px`,
          duration: 0,
        },
        0.4
      )
      .to(
        chars,
        {
          opacity: 1,
          duration: 0.2,
          stagger: {
            from: 'random',
            amount: 0.25,
          },
        },
        0.5
      )
  }
}

function animateMouseleave() {
  if (!hasIcon.value || !el.value || props.pseudodisabled) return
  const icon = el.value.querySelector('.button-pill__icon')
  const text = el.value.querySelector('.button-pill__text')

  const elTop = bounds.el.top
  const elBottom = bounds.el.bottom
  const elLeft = bounds.el.left
  const elRight = bounds.el.right

  const iconTop = bounds.icon.top
  const iconBottom = bounds.icon.bottom

  const contentLeft = bounds.content.left

  tl?.kill()
  tl = gsap.timeline()

  if (props.icon === 'download') {
    const chevron = icon.querySelector('.icon-download__chevron')
    const line = icon.querySelector('.icon-download__line')
    // animate icon down

    tl.addLabel('part1')
      .to(
        line,
        {
          scaleX: 0,
          transformOrigin: 'top left',
          duration: 0.2,
          ease: 'power2.inOut',
        },
        'part1'
      )
      .to(
        chevron,
        {
          scaleX: 0.5,
          scaleY: 1.1,

          duration: 0.4,
          ease: 'power2.in',
        },
        'part1'
      )
      .to(
        chevron,
        {
          y: `${elBottom - iconTop}px`,
          opacity: 0,

          duration: 0.4,
          ease: 'power3.in',
        },
        'part1'
      )
      .to(icon, {
        duration: 0,
        x: 0,
      })
      .to(chevron, {
        y: `${elTop - iconBottom}px`,
        duration: 0,
      })
      .addLabel('part2')
      .to(
        line,
        {
          transformOrigin: 'top right',
          scaleX: 1,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        'part2'
      )
      .to(
        chevron,
        {
          scaleX: 1,
          scaleY: 1,

          duration: 0.4,
          ease: 'power2.out',
        },
        'part2'
      )
      .to(
        chevron,
        {
          y: 0,
          opacity: 1,
          duration: 0.4,
          ease: 'power3.out',
        },
        'part2'
      )
  } else {
    // animate icon to the right
    tl.to(icon, {
      x: `${elRight - contentLeft}px`,
      duration: 0.4,
      ease: 'power3.in',
    })
      .to(icon, {
        x: `${elLeft - contentLeft}px`,
        duration: 0,
      })
      .to(icon, {
        x: 0,
        duration: 0.4,
        ease: 'power3.out',
      })
  }

  if (hasText.value) {
    const { chars } = st
    tl.to(
      chars,
      {
        opacity: 0,
        duration: 0.15,
        stagger: {
          from: 'random',
          amount: 0.15,
        },
      },
      0
    )
      .to(
        text,
        {
          x: `0`,
          duration: 0,
        },
        0.4
      )
      .to(
        chars,
        {
          opacity: 1,
          duration: 0.2,
          stagger: {
            from: 'random',
            amount: 0.25,
          },
        },
        0.5
      )
  }
}

function onMouseenter() {
  if (props.animate) {
    animateMouseenter()
  }
}

function onMouseleave() {
  if (props.animate) {
    animateMouseleave()
  }
}

watch(
  () => props.disabled,
  (value) => {
    if (value) {
      animateMouseleave()
    } else {
      getBounds()
    }
  }
)

/**
 * Mouse gradient animation
 */
let running = true
const mousePosition = useMouse()
const lerpedPosition = new Vec2(0, 0)
const gradientPosition = reactive({ x: 0, y: 0 })
const useGradient = computed(() => !props.outline)

const MARGIN = {
  top: 50,
  right: 50,
  bottom: 50,
  left: 50,
}

function animate() {
  if (running) requestAnimationFrame(animate)
  if (!el.value) return

  const top = bounds.el.top
  const right = bounds.el.right
  const bottom = bounds.el.bottom
  const left = bounds.el.left

  const { x, y } = mousePosition

  lerpedPosition.set(
    clamp(
      lerp(
        lerpedPosition.x,
        (x - (left - MARGIN.left)) /
          (right + MARGIN.right - (left - MARGIN.left)),
        0.1
      ),
      0,
      1
    ),
    clamp(
      lerp(
        lerpedPosition.y,
        (y - (top - MARGIN.top)) /
          (bottom + MARGIN.bottom - (top - MARGIN.top)),
        0.1
      ),
      0,
      1
    )
  )

  gradientPosition.x = lerpedPosition.x
  gradientPosition.y = lerpedPosition.y
}

watchEffect(() => {
  if (el.value) {
    const gradientEl = el.value.querySelector('.button-pill__gradient')
    const movementRange = 15
    if (gradientEl) {
      gradientEl.style.transform = `
        translate3d(
          ${-50 + (gradientPosition.x * 2 - 1) * movementRange}%,
          ${-50 + (gradientPosition.y * 2 - 1) * movementRange}%,
          0
        )
      `
    }
  }
})

onMounted(() => {
  getBounds()
  GlobalEmitter.on('debouncedResize', getBounds)
  if (useGradient.value) {
    animate()
  }
})

onBeforeUnmount(() => {
  GlobalEmitter.off('debouncedResize', getBounds)
  running = false
})
</script>

<template>
  <component
    ref="el"
    :is="attrs.href ? 'a' : tag"
    class="button-pill"
    :class="{
      'button-pill--big': big,
      'button-pill--outline': outline,
      'button-pill--minimal': minimal,
      'button-pill--has-icon': hasIcon,
      'button-pill--has-text': hasText,
      'button-pill--has-both': hasIcon && hasText,
      'button-pill--disabled': pseudodisabled,
    }"
    :disabled="disabled"
    v-bind="attributes"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
  >
    <div class="button-pill__content">
      <template v-if="hasIcon">
        <div
          v-if="icon === 'download'"
          class="button-pill__icon"
          :class="`button-pill__icon--${icon}`"
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon-download__chevron"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 2V0.000169992L3.99995 3L8 0V2L3.99995 5L0 2Z"
              fill="white"
            />
          </svg>
          <div class="icon-download__line" />
        </div>
        <Icon
          v-else
          class="button-pill__icon"
          :class="`button-pill__icon--${icon}`"
          :name="icon"
        />
      </template>
      <div v-if="hasText" class="button-pill__text">
        <slot />
      </div>
    </div>

    <div v-if="useGradient" class="button-pill__gradient" />
  </component>
</template>

<style lang="scss">
@use 'sass:math';

// Vendors
@import 'styles/vendors/sass-mq/mq';

@import 'styles/vendors/sass-mq/mq';
@import 'styles/utils/variables';

.button-pill {
  align-items: center;
  display: inline-flex;
  position: relative;
  color: $brand-white;
  padding: 0 24px;
  height: 46px;
  cursor: pointer;
  border-radius: 60px;
  overflow: hidden;

  &::after {
    content: '';
    background: linear-gradient(110.59deg, #c80afb 7.52%, #2919a9 153.34%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    border-radius: 60px;
  }

  &__gradient {
    background: radial-gradient(
      circle at center,
      #c80afb 7.52%,
      #2919a9 153.34%
    );
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 100px);
    padding-top: calc(100% + 100px);
    z-index: 2;
    pointer-events: none;

    .button-pill--big & {
      width: calc(100% + 150px);
      padding-top: calc(100% + 150px);
    }
  }

  &:disabled,
  &--disabled {
    color: #1c0630;
    cursor: default;

    &::after {
      background: #7c55b8;
    }
    .button-pill__icon {
      color: #1c0630;
    }
    .button-pill__gradient {
      opacity: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 3;
  }

  &__text {
    font-weight: $font-weight-4G;
    font-size: 11px;
    line-height: math.div(12, 12);
    letter-spacing: 0.24em;
    text-transform: uppercase;
    white-space: nowrap;

    font-kerning: none; // important for animation

    @include mq($until: 'm') using ($from) {
      font-size: 10px;
      letter-spacing: 0.2em;
    }
  }

  &--outline {
    padding: 0 20px;
    height: 44px;

    @include mq($from: 'm') using ($from) {
      padding: 0 24px;
      height: 46px;
    }

    .button-pill__text {
      font-size: 10px;

      @include mq($from: 'm') using ($from) {
        font-size: 11px;
      }
    }
  }

  &--outline::after {
    border: 2px solid transparent;
    background-image: linear-gradient(
      103.2deg,
      #d90afb -13.13%,
      $brand-blue 93.9%
    );
    background-size: calc(100% + 4px) calc(100% + 4px);
    background-position: center;
    -webkit-mask: linear-gradient($brand-white 0 0) padding-box,
      linear-gradient($brand-white 0 0);
    mask: linear-gradient($brand-white 0 0) padding-box,
      linear-gradient($brand-white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &--has-icon:not(&--has-both) {
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;

    @include mq($from: 'ml') using ($from) {
      width: 40px;
    }
  }

  &--minimal {
    padding: 0;
    height: auto;
    transition: color 0.3s;
    &:hover {
      color: $brand-magenta;
    }

    &.button-pill--has-both .button-pill__icon {
      margin-right: 12px;
    }
    .button-pill__gradient {
      display: none;
    }
    &::after {
      background: none;
    }
  }

  &--has-both {
    .button-pill__content {
      display: flex;
      align-items: center;
    }
    .button-pill__icon {
      margin-right: 4px;
    }
  }

  &--big {
    padding: 22px 56px 22px 52px;
    height: 60px;
  }

  $icons: (
    'chevron-right': (
      14,
      14,
    ),
    'external-link': (
      14,
      14,
    ),
  );

  &__icon {
    @each $icon, $size in $icons {
      &--#{$icon} {
        width: #{nth($size, 1)}px;
        height: #{nth($size, 2)}px;
      }
    }
  }

  // special case: download
  &__icon--download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    position: relative;

    .icon-download__line {
      position: absolute;
      background: $brand-white;
      bottom: 3px;
      left: 3px;
      width: 8px;
      height: 1.5px;
    }
  }

  // this broke all ButtonPill instances with Icons — use specific classes to target
  // & span {
  //   color: rgba($brand-white, 0.7);
  //   padding-left: 5px;

  //   &::before {
  //     content: '  •  ';
  //   }
  // }
}
</style>
